export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Colorbox Incredibox",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://kdata1.com/2024/10/colorbox-mustard/",
    domain: "colorboxmustard.net",
    gaId: "G-T6K80JF1BH",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
